import React from 'react'
import TeamMember from './components/TeamMember'
import {
  faInstagram,
  faTiktok,
  faSpotify,
} from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

export default function Team() {
  return (
    <>
      {/* Example: a single block with Anna & Moritz side by side */}
      <TeamMember
        sectionId="anna-moritz-section"
        imageSrc="/images/moanna.jpeg"
        people={[
          {
            id: 'anna-goller',
            name: 'Anna Goller',
            birthDate: '1991-10-16',
            city: 'Berlin',
            jobTitle: 'Gründerin / Künstler Managerin',
            email: 'anna@mag-musik.de',
            socials: [
              { icon: faInstagram, url: 'https://www.instagram.com/annadoller/' },
              { icon: faTiktok,    url: 'https://www.tiktok.com/@annamagmusik' },
              { icon: faSpotify,   url: 'https://open.spotify.com/user/annadoller?si=869ad530b86f4434' },
            ],
          },
          {
            id: 'moritz-goller',
            name: 'Moritz Goller',
            birthDate: '1993-06-29',
            city: 'Berlin',
            jobTitle: 'Gründer / Künstler Manager',
            email: 'moritz@mag-musik.de',
            socials: [
              { icon: faInstagram, url: 'https://www.instagram.com/morauch/' },
              { icon: faTiktok,    url: 'https://tiktok.com/@momagmusik' },
              { icon: faSpotify,   url: 'https://open.spotify.com/user/moritzgoller?si=240f7dcf9bd34b99' },
            ],
          },
        ]}
      />

      {/* If you wanted a single-person block later, for example: */}
      <TeamMember
        sectionId="danja-bumler-section"
        imageSrc="/images/danja.jpeg"
        people={[
          {
            id: 'danja-bumler',
            name: 'Danja Bumler',
            jobTitle: 'Künstler Managerin',
            email: 'danja@mag-musik.de',
            socials: [
              { icon: faInstagram, url: 'https://www.instagram.com/danjabmlr/' },
            ],
          },
        ]}
        reverse={true}
      />
      <TeamMember
        sectionId="jana-breuer-section"
        imageSrc="/images/Jana.jpeg"
        people={[
          {
            id: 'jana-breuer',
            name: 'Jana Breuer',
            jobTitle: 'Werkstudentin',
            email: 'jana@mag-musik.de',
            socials: [
              { icon: faInstagram, url: 'https://www.instagram.com/jaeyla/' },
            ],
          },
        ]}
      />
    </>
  )
}
