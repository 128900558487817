import React from 'react'
import { useLayoutEffect } from 'react'
import Artist from './components/Artist'
import './css/Management.css'
import './css/Verlag.css'
import './css/AnimatedHeading.css'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/all'
import AnimatedHeading from './components/AnimatedHeading'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import TeamMember from './components/TeamMember'

export default function Verlag()
{
    return (
        <>
        <div className="management-row">
            <h1 className='verlag-title'>Autoren</h1>
            <TeamMember
                sectionId="paul-wetz-section"
                imageSrc="/images/paulwetz.jpeg"
                people={[
                    {
                        id: 'paul-wetz',
                        name: 'Paul Wetzler',
                        jobTitle: 'Writer & Producer',
                        email: 'moritz@mag-musik.de',
                        socials: [
                            { icon: faInstagram, url: 'https://www.instagram.com/paulwetz/' },
                        ],
                    },
                ]}
            />
        </div>
        </>
    )
}
