import React, { useRef, useLayoutEffect } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/all'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import {
  faInstagram,
  faTiktok,
  faSpotify,
} from '@fortawesome/free-brands-svg-icons'

import '../css/Team.css' // or wherever your styles are

// Utility to calculate an age from birthDate
const getAge = (birthDate) => {
  return Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e10)
}

/**
 * Reusable TeamMember component.
 *
 * @param {Object}   props
 * @param {string}   props.sectionId  - Unique ID for the entire section (trigger).
 * @param {string}   props.imageSrc   - Path to the shared image for this block.
 * @param {Array}    props.people     - An array of person objects:
 *        [{ id: 'anna-goller', name: 'Anna Goller', birthDate: '1991-10-16', city: 'Berlin', email: 'anna@...', socials: [...], }, ...]
 * @param {boolean}  props.reverse    - Whether to reverse the layout (image on right, text on left).
 */
function TeamMember({ 
  sectionId = 'team-section',
  imageSrc = '/images/moanna.jpeg',
  people = [],
  reverse = false,
}) {
  // Register GSAP plugin
  gsap.registerPlugin(ScrollTrigger)

  // Refs
  const sectionRef   = useRef(null) // the main .team-wrapper
  const descWrapRef  = useRef(null) // the .moanna-description
  const imageRef     = useRef(null) // <img>
  
  // We'll store each person's <div> ref by their ID (optional, if you need direct references later)
  const membersRef   = useRef({})

  useLayoutEffect(() => {
    const descriptionWrap = descWrapRef.current
    const teamImage       = imageRef.current

    // Build a timeline
    const tl = gsap.timeline()

    // 1) Animate the .moanna-description container
    tl.to(descriptionWrap, {
      y: 0,
      opacity: 1,
      duration: 0.7,
      ease: 'power3.out',
    })
    // 2) Animate the image
    .to(teamImage, {
      x: 0,
      opacity: 1,
      duration: 0.5,
      ease: 'power3.out',
    })

    // 3) For each person, animate in sequence
    people.forEach((person) => {
      const { id } = person
      tl.to(`#${id}`, {
        x: 0,
        opacity: 1,
        duration: 0.4,
        ease: 'power3.out',
      })
      .to(`#${id} h1`, {
        y: 0,
        opacity: 1,
        duration: 0.3,
      })
      .to(`#${id} svg`, {
        y: 0,
        opacity: 1,
        stagger: 0.07,
      })
    })

    // Attach ScrollTrigger
    ScrollTrigger.create({
      trigger: sectionRef.current,
      start: 'top center',
      end: 'top 50%',
      markers: false, // set true if debugging
      toggleActions: 'play none none reverse',
      animation: tl,
      invalidateOnRefresh: true,
    })
  }, [people, reverse])

  return (
    <div
      ref={sectionRef}
      className={`team-wrapper ${reverse ? 'reverse' : ''}`}
      id={sectionId}
    >
      <div className="moanna-wrapper">
        {/* Conditionally set initial translateX based on reverse (mirror animation if desired) */}
        <img
          ref={imageRef}
          src={imageSrc}
          alt="team"
          style={{
            transform: reverse ? 'translateX(-100%)' : 'translateX(100%)',
            opacity: 0,
          }}
        />
      </div>

      <div
        ref={descWrapRef}
        className="moanna-description"
        style={{ transform: 'translateY(-100%)', opacity: 0 }}
      >
        {people.map((person) => {
          const { id, name, jobTitle, birthDate, city, email, socials = [] } = person
          const age = birthDate ? getAge(birthDate) : null

          return (
            <div
              key={id}
              id={id}
              ref={(el) => { membersRef.current[id] = el }}
              /* If reversing, you can mirror the text block's X transform as well */
              style={{
                transform: reverse ? 'translateX(100%)' : 'translateX(-100%)',
                opacity: 0,
                paddingTop: '5vw', // mimic your original spacing
              }}
            >
              {/* Split the name line like Anna \n Goller */}
              <h1 style={{ transform: 'translateY(10%)', opacity: 0 }}>
                {name.split(' ')[0]} <br />
                {name.split(' ').slice(1).join(' ')}
              </h1>
              {jobTitle !== null && age == null && <p>{jobTitle}</p>}
              {age !== null ? (
                <p>
                  {jobTitle} {age}, {city}
                </p>
              ) : (
                <p>{city}</p>
              )}

              <hr className="first-hr" />

              {email && (
                <a href={`mailto:${email}`}>
                  <FontAwesomeIcon icon={faEnvelope} />
                </a>
              )}

              {socials.map(({ icon, url }, idx) => (
                <a key={idx} href={url} target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={icon} />
                </a>
              ))}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default TeamMember
