import './css/Footer.css'
import { gsap } from 'gsap/all'
import Title from './components/Title'
import { useRef, useEffect } from 'react'

function Footer() {
    const impressum = useRef()
    const kontakt = useRef()

    return (
        // TODO: footer mobile and footer font.size too big
        <footer className="app-footer">
            <div className="footer-logo-wrapper">
                <Title />
                <p>Musik & Management 2016</p>
            </div>
            <div className="footer-section">
                <h1 className="impressum highlight toggle" ref={impressum}>
                    Impressum
                </h1>
                <h1 className="kontakt highlight toggle" ref={kontakt}>
                    Kontakt
                </h1>
                <h1 className="highlight toggle">Datenschutzerklärung</h1>
            </div>
            <div className="footer-section">  
                <a href="mailto:info@mag-musik.de">
                    <h1>schick uns</h1>
                    <h1>deine Musik</h1>
                    {/* schick uns <br />
                    deine Musik */}
                </a>         
            </div>
        </footer>
    )
}

export default Footer
