import './css/App.css'
import StickyNavbar from './components/StickyNavbar'
import MobileNav from './components/MobileNav'
import Management from './Management'
import Team from './Team'
import Footer from './Footer'
import Label from './Label'

import { useRef, useEffect, useState } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger, ScrollSmoother } from 'gsap/all'
import { SplitText } from 'gsap/src/all'
import { Power3 } from 'gsap'
import AnimatedHeading from './components/AnimatedHeading'
import LoadingScreen from './LoadingScreen'
import Modal from './components/Modal'
import CookiePopUp from './components/CookiePopUp'
import Ghost from './components/ghost/Ghost'
import Verlag from './Verlag'

function App()
{
    gsap.registerPlugin(ScrollTrigger, SplitText, ScrollSmoother, Power3)

    // Refs
    const ref = useRef(null)
    const smoothWrapper = useRef()
    const smoothContent = useRef()
    const labelSection = useRef()
    const verlagSection = useRef()
    const teamSection = useRef()

    // Loading state: always start true, so we show the loading screen
    const [loading, setLoading] = useState(true)

    // Hide .App container initially (autoAlpha: 0)
    useEffect(() =>
    {
        if (ref.current)
        {
            gsap.set(ref.current, { autoAlpha: 0 })
        }
    }, [])

    useEffect(() =>
    {
        // Prevent scrolling while loading
        document.body.style.overflowY = 'hidden'

        const MIN_DURATION = 4500 // 4.5 second minimum (adjust as desired)

        // Function to hide the loading screen after the min duration
        const hideLoading = () =>
        {
            setLoading(false)
            document.body.style.overflowY = 'unset' // restore scroll
        }

        // We'll store the timeout ID if we need to clear it
        let minDurationTimeout

        // If the page is already fully loaded (cached, etc.), 
        // we still show the loading screen for at least 1 second.
        if (document.readyState === 'complete')
        {
            minDurationTimeout = setTimeout(hideLoading, MIN_DURATION)
        } else
        {
            // Otherwise, wait for the 'load' event
            const handleAllLoaded = () =>
            {
                // The page finished loading; also respect the minimum display time
                minDurationTimeout = setTimeout(hideLoading, MIN_DURATION)
            }
            window.addEventListener('load', handleAllLoaded)

            // Cleanup
            return () =>
            {
                window.removeEventListener('load', handleAllLoaded)
                if (minDurationTimeout) clearTimeout(minDurationTimeout)
            }
        }
    }, [])

    // Once loading is false, fade in .App, plus any GSAP triggers you need
    useEffect(() =>
    {
        if (!loading)
        {
            // Show .App container
            gsap.set(ref.current, { autoAlpha: 1 })

            // Example: background color animation for .lab-section
            gsap.to(labelSection.current, {
                scrollTrigger: {
                    trigger: labelSection.current,
                    start: 'top center',
                    end: '+=200',
                    scrub: 2,
                    markers: false,
                },
                duration: 1,
                backgroundColor: '#1e816d',
                ease: 'none',
            })
            gsap.to(teamSection.current, {
                scrollTrigger: {
                    trigger: teamSection.current,
                    start: 'top center',
                    end: '+=200',
                    scrub: 2,
                    markers: false,
                },
                duration: 1,
                backgroundColor: '#1e816d',
                ease: 'none',
            })
        }
    }, [loading])

    // Optional: Re-enable ScrollSmoother after loading
    // useEffect(() => {
    //     if (!loading) {
    //         const smoother = ScrollSmoother.create({
    //             wrapper: smoothWrapper.current,
    //             content: smoothContent.current,
    //             smooth: 2,
    //         })
    //         return () => smoother.kill()
    //     }
    // }, [loading])

    // Simple halloween check
    const isToday = (someDate) =>
    {
        const today = new Date()
        return (
            someDate.getDate() === today.getDate() &&
            someDate.getMonth() === today.getMonth() &&
            someDate.getFullYear() === today.getFullYear()
        )
    }
    const halloween = new Date('2022-10-31')

    return (
        <div ref={smoothWrapper}>
            <div ref={smoothContent}>
                {/* Show the loading screen as long as "loading" is true */}
                {loading && <LoadingScreen />}

                {/* Main App Container, hidden while loading */}
                <div ref={ref} className="App">
                    {isToday(halloween) && <Ghost />}
                    <CookiePopUp />
                    <Modal />

                    <div className="content-wrap">
                        <MobileNav />
                        <StickyNavbar />

                        <div className="content">
                            <section className="man-section">
                                <Management />
                            </section>
                            <section ref={labelSection} className="lab-section">
                                <Label />
                            </section>
                            <section ref={verlagSection} className="v-section">
                                <AnimatedHeading lineOne={'mag'} lineTwo={'verlag'} />
                                <Verlag />
                            </section>
                            <section ref={teamSection} className="team-section">
                                <AnimatedHeading lineOne={'mag'} lineTwo={'team'} />
                                <Team />
                            </section>
                        </div>

                        <Footer />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default App
